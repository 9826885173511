<template>
    <!-- <div class="wrap" style="padding-top: 1.80rem;padding-bottom: 1.4rem;"> -->
    <div class="wrap"  >
        <Header></Header>
        <router-view ref="router"></router-view>
        <Backtop></Backtop>
        <Footer></Footer>
    </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "@assets/css/swiper.min.css";
import { getHomeData } from "@api/public";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Backtop from "./components/Backtop";
import { Tab, Tabs } from "vant";
export default {
    name: "Index",
    components: {
        Header, swiper, swiperSlide, Footer, Tab, Tabs, Backtop 
    },
    props: {},
    data: function() {
        return {
            active: 0,
            swiperOption: {
                slidesOffsetBefore : 40,
                slidesOffsetAfter : 40,
                slidesPerView: 'auto',
                centeredSlides:false,
                spaceBetween: 20,
                loop: true,
                lazy: true,
            }
        };
    },
    created: function() {

    },
    mounted: function() {

    },
    methods: {
        changeactive: function(e) {
            // this.$router.push({ path: this.TabsList[e].url });
        },
    }
};
</script>
<style scoped lang="scss">
.banner{
    img{width: 100%;height: auto;}
}
.swipercon{
    background: #fff;margin-top: 0.2rem;
    .tit{
        img{width: 100%;}
    }
}
.swiper {
    width: 100%;height: 100%;
    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: bold;background: #fff;border-radius: 0.2rem;margin: 0.2rem 0 0.8rem;box-shadow: 0 0.16rem 0.5rem rgba(0, 0, 0, 0.2);height: 8.4rem;width: 6rem!important;overflow: hidden;
    }
}
</style>
